import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['input', 'output']
  static values = {
    operator: String,
    constant: Number,
    prefix: String,
    suffix: String
  }

  connect() {
    this.calculate()
  }

  calculate() {
    const result = this.operatorFunctions(this.operatorValue)(this.inputTarget.value, this.constantValue);
    const formatted = result.toLocaleString('en-US', {maximumFractionDigits:2})
    this.outputTarget.innerText = `${this.prefixValue}${formatted}${this.suffixValue}`
  }

  operatorFunctions(operator) {
    return {
      "*" : (x, y) => { return x * y },
      "+" : (x, y) => { return x + y },
      "-" : (x, y) => { return x - y },
    }[operator]
  }
}
