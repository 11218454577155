import { Controller } from "stimulus"
import Swiper from 'swiper'

export default class extends Controller {
  connect() {
    const swiper = new Swiper(this.element, {
      slidesPerView: "auto",
      freeMode: true,
      freeModeSticky: true,
      freeModeMinimumVelocity: 0.5,
      grabCursor: true,
      spaceBetween: 16,
      enabled: true,
      breakpoints: {
        1140: {
          enabled: false
        }
      },
      on: {
        fromEdge: function(swiper) { 
          swiper.el.classList.remove('reached-beginning', 'reached-end') 
        },
        reachBeginning: function(swiper) { 
          swiper.el.classList.add('reached-beginning') 
        },
        reachEnd: function(swiper) { 
          swiper.el.classList.add('reached-end') 
        }
      }
    });
  }
}
