import { Controller } from 'stimulus'
import * as WebAuthnJSON from '@github/webauthn-json'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = ['user', 'nickname']
  static values = { callback: String }

  create(event) {
    const [data, status, xhr] = event.detail
    const _this = this

    WebAuthnJSON.create({ publicKey: data })
      .then(async function (credential) {
        const request = new FetchRequest(
          'post',
          _this.callbackValue,
          { body: JSON.stringify({credential: credential, nickname: _this.nicknameTarget.value, admin_user: _this.userTarget.value}), responseKind: 'html' },
        )
        const response = await request.perform()

        if (response.ok) {
          const data = await response.json
          window.location.replace(data.redirect)
        } else {
          window.location.reload()
        }

      })
      .catch(function (error) {
        console.log('something is wrong', error)
      })
  }

  error(event) {
    console.log('something is wrong', event)
  }
}
