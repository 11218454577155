import { Controller } from "stimulus"
import basictable from 'JS/scripts/basictable.js'

export default class extends Controller {
  static targets = ['toggle', 'content']

  connect() {
    if (this.isOverflowingBody()) {
      this.contentTarget.classList.add('show--below')
      if (this.isOverflowingBody()) {
        this.contentTarget.classList.remove('show--below')
        this.contentTarget.classList.add('show--left')
      }
    }   
  }

  isOverflowingBody() {
    const bodyRect = document.body.getBoundingClientRect()
    const elRect = this.contentTarget.getBoundingClientRect();

    return elRect.right > bodyRect.width
  }

  toggle() {
    this.contentTarget.classList.toggle('active')
  }
}
