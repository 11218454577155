document.addEventListener('DOMContentLoaded', () => {
  const POLICY_STORE_KEY   = 'IT_COOKIE_POLICY'
  const POLICY_STORE_VALUE = 'ACCEPTED'

  const el = document.getElementById('cookies-bar')

  if (el === null) return;

  const close = el.querySelector('button')
  const moreInfo = el.querySelector('a')

  let policy_status = localStorage.getItem(POLICY_STORE_KEY)

  if (policy_status != POLICY_STORE_VALUE) {
    el.classList.remove('closed')
    el.addEventListener('click', function(e) {
      if (e.target != moreInfo) {
        el.classList.add('closed')
        el.remove()
        localStorage.setItem(POLICY_STORE_KEY, POLICY_STORE_VALUE)
      }
    })
  } else {
    el.remove()
  }
})
