const InputReset = {
  clear(el) {
    if (el == null) { return }
    let input = el.tagName == "INPUT" ? el : el.querySelector("input:not([type=hidden])") // Don"t reset inputs not set by user.
    if (input !== null) {
      switch (input.type) {
        case "radio":
          this.clearCheckbox(input)
          break
        case "checkbox":
          this.clearCheckbox(input)
          break
        default:
      }
    }
  },
  clearCheckbox(input) {
    if (!input.checked) { return }
    input.checked = false
    this.dispatchChangeEvent(input)
  },
  dispatchChangeEvent(input) {
    const event = document.createEvent("Event")
    event.initEvent("change", true, true)
    input.dispatchEvent(event)
  },
}

export default InputReset
