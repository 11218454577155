import { Controller } from "stimulus"
import ImageCompare from "image-compare-viewer";

export default class extends Controller {
  connect() {
    const options = {
      controlColor: "#FFFFFF",
      controlShadow: false,
      addCircle: true,
      addCircleBlur: false,

      smoothing: true,
      smoothingAmount: 300,

      hoverStart: false,
    };

    const image = new ImageCompare(this.element, options).mount();
  }
}
