import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['labelValue', 'input', 'chart', 'initial', 'year5', 'year10', 'year15', 'year20', 'propertyMarket', 'traditionalMarket']

  static values = {
    traditional: Number,
    initial: Number,
    year5: Number,
    year10: Number,
    year15: Number,
    year20: Number,
  }

  connect() {
    const newValue = this.inputTarget.value

    this.labelValueTarget.innerHTML = this.formatPounds(newValue)

    this.updateChartData(newValue)
  }

  updateValue(e) {
    const newValue = e.target.value

    this.labelValueTarget.innerHTML = this.formatPounds(newValue)

    this.updateChartData(newValue)
  }

  updateChartData(base) {
    base = parseInt(base)

    // Calculate
    const initial = this.percentOf(base, this.initialValue)
    const traditional = this.increaseByPercentage(base, this.traditionalValue)
    const year5 = this.increaseByPercentage(base, this.year5Value)
    const year10 = this.increaseByPercentage(base, this.year10Value)
    const year15 = this.increaseByPercentage(base, this.year15Value)
    const year20 = this.increaseByPercentage(base, this.year20Value)

    // Apply data on chart
    this.initialTarget.innerHTML = this.formatPounds(initial)
    this.year5Target.innerHTML = this.formatPounds(year5)
    this.year10Target.innerHTML = this.formatPounds(year10)
    this.year15Target.innerHTML = this.formatPounds(year15)
    this.year20Target.innerHTML = this.formatPounds(year20)

    // Apply data on summary
    if (this.hasPropertyMarketTarget) {
      this.propertyMarketTarget.innerHTML = this.formatPounds(year20)
    }
    if (this.hasTraditionalMarketTarget) {
      this.traditionalMarketTarget.innerHTML = this.formatPounds(traditional)
    }
  }

  increaseByPercentage(value, percentage) {
    return value + this.percentOf(value, percentage)
  }

  percentOf(value, percentage) {
    return value * percentage;
  }

  formatPounds(number) {
    const numberToCompare = Math.abs(number)
    let newNumber, suffix

    if (numberToCompare >= 1000000) {
      newNumber = (number / 1000000).toFixed(1)
      suffix = 'M'
    } else if (numberToCompare >= 1000) {
      newNumber = (number / 1000).toFixed(1)
      suffix = 'K'
    } else {
      newNumber = number
      suffix = ''
    }

    const formatedNumber = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(newNumber)

    return formatedNumber + suffix
  }
}
