import { Controller } from "stimulus"

export default class extends Controller {
  static values = { fieldsetClasses: String }

  connect() {
    this.fieldsets = this.element.querySelectorAll("fieldset")
    const lastFieldset = this.fieldsets[this.fieldsets.length - 1]
    this.calculationFieldset = lastFieldset.parentNode.insertBefore(
      lastFieldset.cloneNode(true),
      lastFieldset.nextElementSibling,
    )

    this.prepareReadonlyFieldset(this.calculationFieldset)
    this.runCalculations()
  }

  calculate(e) {
    this.runCalculations()
  }

  prepareReadonlyFieldset(fieldset) {
    fieldset.setAttribute("disabled", true)
    fieldset.classList.add(this.fieldsetClasses)
    fieldset
      .querySelectorAll("[data-calculation-fields-hidden]")
      .forEach((hiddenInput) => {
        hiddenInput.style.opacity = 0
      })
    const deleteToggle = fieldset.querySelector(".has_many_delete")
    if (deleteToggle !== null) { deleteToggle.remove() }
    const removeToggle = fieldset.querySelector(".has_many_remove")
    if (removeToggle !== null) { removeToggle.remove() }
  }

  runCalculations() {
    this.calculationFieldset.querySelectorAll("input").forEach((input) => {
      const fieldNameSelector = `[name*=${input.name.match(/[^[\}]+(?=])(?!.*[^[\}]+(?=]))/g)}]`
      let calcables = []
      let total = 0
      this.fieldsets
        .forEach((fieldset) => {
          calcables.push(fieldset.querySelector(fieldNameSelector))
        })
      calcables.forEach((input) => { total += Number(input.value) })
      input.value = total
    })
  }

  get fieldsetClasses() {
    return this.fieldsetClassesValue !== "" ?
      this.fieldsetClassesValue :
      `${this.indentifier}-fieldset`
  }
}
