import { Controller } from "stimulus"
import { Chart, DoughnutController, ArcElement, Legend, Tooltip } from 'chart.js'

export default class extends Controller {
  static targets = ['canvas', 'legend']

  connect() {
    Chart.register(DoughnutController, ArcElement, Legend, Tooltip)

    const chartData = JSON.parse(this.canvasTarget.dataset.chartdata)
    const defaultBackgrounds = [ '#ede0ab', '#e6a78a', '#F2F4F5', '#7FBBB7', '#37545d', '#e8d896', '#e68b8a', '#bce2df', '#687e84', '#9e7b5f', '#ca652e', '#041c22' ].sort(() => Math.random() - 0.5);
    let labels = []
    let data = []
    let dataDetails = []
    let backgrounds = []

    const legendPosition = this.canvasTarget.dataset.legendPosition
    const legendAlign = this.canvasTarget.dataset.legendAlign
    const disableInteractions = this.canvasTarget.dataset.disableInteractions != undefined ? true : false

    const getOrCreateLegendList = (chart, id) => {
      const legendContainer = this.legendTarget;
      let listContainer = legendContainer.querySelector('ul');

      if (!listContainer) {
        listContainer = document.createElement('ul');
        listContainer.style.display = 'flex';
        listContainer.style.flexDirection = 'row';
        listContainer.style.margin = 0;
        listContainer.style.padding = 0;

        legendContainer.appendChild(listContainer);
      }

      return listContainer;
    };

    const htmlLegendPlugin = {
      id: 'htmlLegend',
      afterUpdate(chart, args, options) {
        const ul = getOrCreateLegendList(chart, options.containerID);

        // Remove old legend items
        while (ul.firstChild) {
          ul.firstChild.remove();
        }

        // Reuse the built-in legendItems generator
        const items = chart.options.plugins.legend.labels.generateLabels(chart);

          items.forEach(item => {
            const li = document.createElement('li');
            const span = document.createElement('span');
            li.style = `--chart-legend-color: ${item.fillStyle};`

            const text = document.createTextNode(typeof item.text == 'object' ? item.text.text[0] : item.text);

            // drop here if label should be hidden
            if (text.data.includes('hide_label')) return

            li.appendChild(text);

            // Add chart item value to Legend Label
            if (typeof item.text == 'object' && item.text.text[1] !== undefined) {
              const subtext = document.createTextNode(item.text.text[1]);
              span.appendChild(subtext);
              li.appendChild(span);
            }

            ul.appendChild(li);
          });
        }
    };

    for (const [k, v] of Object.entries(chartData)) {
      labels.push(k)
      data.push(v.value)
      dataDetails.push(v.details)
      if (v.color != undefined) {
        backgrounds.push(v.color)
      }
    }

    backgrounds = backgrounds.length != 0 ? backgrounds : defaultBackgrounds

    Chart.defaults.font.family = "'MTT Milano', sans-serif";
    Chart.defaults.font.size = 14;
    const chartOptions = {
      // aspectRatio: legendPosition == 'right' || legendPosition == 'left' ? 1.75 : .75,
      responsive: true,
      // maintainAspectRatio: false,
      borderWidth: 1,
      borderColor: "#082c37",
      cutout: '66%',
      interaction: { mode: disableInteractions ? null : 'point' },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: !disableInteractions && function(context) {
            // hide tooltip if it's hidden label
            if (context.tooltipItems != undefined && context.tooltipItems.length == 1) {
              if (context.tooltipItems[0].label.text[0] != undefined) {
                if(context.tooltipItems[0].label.text[0] == 'hide_label ') return false;
              }
            }

            return true;
          },
          callbacks: {
            label: function(context) {
              var label = context.label.text || '';
              return label;
            }
          }
        }
      }
    };

    if (disableInteractions){ chartOptions.animation = false; }

    const chart = new Chart(this.canvasTarget, {
      type: 'doughnut', options: chartOptions, plugins: [htmlLegendPlugin],
      data: {
        labels: labels,
        datasets: [{
          data: data,
          backgroundColor: backgrounds
        }]
      }
    })


    function updateAllLabels() {
      const newLabels = [];

      chart.data.datasets[0]['data'].forEach((data, i) => {
        newLabels.push(generateLabel(data, i));
      });

      chart.data.labels = newLabels;
    }

    function generateLabel(d, i) {
      return {
        text: labels[i].split('\n'),
        fontColor: "#7fbbb7",
        fillStyle: backgrounds[i % backgrounds.length],
        lineWidth: 0,
      }
    }

    updateAllLabels();
    chart.update();
    chart.resize();
  }
}

