import { Controller } from "stimulus"
import InputReset from "./shared/input_reset"

export default class extends Controller {
  static values = {
    hidden: Boolean,
  }

  connect() {
    this.hiddenValue = true
    this.buildToggleLink()
  }

  toggle(e) {
    e.preventDefault()
    this.hiddenValue = !this.hiddenValue
  }

  hiddenValueChanged() {
    this.toggleElements()
    if (this.toggleLink !== undefined) {
      this.toggleLink.innerText = this.hiddenValue ? "Manage records" : "Lock"
    }
  }

  buildToggleLink() {
    this.toggleLink = document.createElement("a")
    this.toggleLink.setAttribute("href", "#")
    this.toggleLink.setAttribute("data-action", `${this.identifier}#toggle`)
    this.toggleLink.className = "admin-link admin_gutter admin-text-right"
    this.toggleLink.appendChild(document.createTextNode("Manage records"))
    this.element.append(this.toggleLink)
  }

  toggleElements() {
    let elements = this.element.querySelectorAll(
      ".has_many_add, .has_many_delete, .has_many_remove",
    )
    if (elements !== undefined) {
      elements.forEach((el) => {
        el.setAttribute("hidden", this.hiddenValue)
        if (this.hiddenValue == true) { InputReset.clear(el) }
      })
    }
  }
}
