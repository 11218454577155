import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    disabled: Boolean,
    linkClasses: String,
    linkText: String,
  }

  connect() {
    this.buildToggleLink()
  }

  toggle(e) {
    e.preventDefault()
    this.disabledValue = !this.disabledValue
  }

  disabledValueChanged() {
    this.element.setAttribute("readonly", this.disabledValue)
    if (this.editLink !== undefined) {
      this.editLink.innerText = this.disabledValue ? this.linkText : "Lock"
    }
  }

  buildToggleLink() {
    this.editLink = document.createElement("a");
    this.editLink.setAttribute("href", "#")
    this.editLink.setAttribute("data-action", `${this.identifier}#toggle`)
    this.editLink.className = this.linkClasses
    this.editLink.appendChild(document.createTextNode(this.linkText))
    this.element.insertBefore(this.editLink, this.element.firstChild)
  }

  get linkClasses() {
    return this.linkClassesValue != "" ?
      this.linkClassesValue :
      `${this.identifier}-link`
  }

  get linkText() {
    return this.linkTextValue != "" ?
      this.linkTextValue :
      "Edit"
  }
}
