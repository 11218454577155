import { Controller } from "stimulus";

export default class extends Controller {
  /*connect() {
    const numberInput = this.element.querySelector(
      ".formatted-financial-amount"
    );
    numberInput.addEventListener("input", this.formatNumericInput.bind(this));
  }*/

  incomeChanged(e) {
    const nextStepRadio = document.querySelectorAll(
      '[type="radio"][name="investor_categorisation[next_step_path]"]'
    );
    const highIncomeInput = document.querySelector(
      'input[name="investor_categorisation[answers][high_income]"]'
    );
    const explainer = document.getElementById("explainer");

    nextStepRadio.checked = false;
    const prefix = "Income is ";
    if (parseInt(e.currentTarget.value, 10) >= 100000) {
      nextStepRadio[0].checked = true;
      explainer.innerHTML = prefix + "£100k or above";
      highIncomeInput.value = "☐ No  ▣ Yes";
    } else {
      nextStepRadio[1].checked = true;
      explainer.innerHTML = prefix + "below £100k";
      highIncomeInput.value = "▣ No  ☐ Yes";
    }
    const numberInput = this.element.querySelector(
      ".formatted-financial-amount"
    );
    numberInput.addEventListener("input", this.formatNumericInput.bind(this));
  }

  netAssetsChanged(e) {
    console.log("netAssetsChanged");
    const nextStepRadio = document.querySelectorAll(
      '[type="radio"][name="investor_categorisation[next_step_path]"]'
    );
    const highNetAssetsInput = document.querySelector(
      'input[name="investor_categorisation[answers][high_net_assets]"]'
    );
    const explainer = document.getElementById("explainer");

    nextStepRadio.checked = false;
    const prefix = "Net assets are ";
    if (parseInt(e.currentTarget.value, 10) >= 500000) {
      nextStepRadio[0].checked = true;
      explainer.innerHTML = prefix + "£500k or above";
      highNetAssetsInput.value = "☐ No  ▣ Yes";
    } else if (parseInt(e.currentTarget.value, 10) >= 250000) {
      nextStepRadio[1].checked = true;
      explainer.innerHTML = prefix + "£250k or above";
      highNetAssetsInput.value = "☐ No  ▣ Yes";
    } else {
      nextStepRadio[2].checked = true;
      explainer.innerHTML = prefix + "below £250k";
      highNetAssetsInput.value = "▣ No  ☐ Yes";
    }
    const numberInput = this.element.querySelector(
      ".formatted-financial-amount"
    );
    numberInput.addEventListener("input", this.formatNumericInput.bind(this));
  }

  limitInvestmentsChanged() {
    const validInput = document.getElementById(
      "investor_categorisation_next_step_path"
    );

    const currentLimitValid = document.getElementById(
      "investor_categorisation_answers_current_investment_limit_less_than_10"
    ).checked;
    const plannedLimitValid = document.getElementById(
      "investor_categorisation_answers_planned_investment_limit_less_than_10"
    ).checked;

    const current = document.getElementById(
      "investor_categorisation_answers_current_investment_percentage"
    ).value;
    const planned = document.getElementById(
      "investor_categorisation_answers_planned_investment_percentage"
    ).value;

    const validAsRestricted =
      current &&
      parseInt(current) <= 10 &&
      planned &&
      parseInt(planned) <= 10 &&
      currentLimitValid &&
      plannedLimitValid;

    validInput.checked = validAsRestricted;
  }

  formatNumericInput(event) {
    console.log("input formatter");
    const target = event.target;
    const cursorPosition = target.selectionStart;
    const oldValue = target.value;

    const newValue = oldValue
      .replace(/[^\d.]/g, "")
      .replace(/^(\d*\.\d*)\./g, "$1");
    const parts = newValue.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    if (parts.length > 1) {
      parts[1] = parts[1].replace(/[^0-9]/g, "").slice(0, 2); // Allow only two decimal places
    }

    const formattedValue = parts.join(".");
    const diff = formattedValue.length - oldValue.length;
    target.value = formattedValue;
    let newCursorPosition = cursorPosition + diff;
    if (cursorPosition === oldValue.length) {
      newCursorPosition = formattedValue.length;
    }

    target.setSelectionRange(newCursorPosition, newCursorPosition);
  }
}
