// This is a manifest file that'll be compiled into application.js, which will include all the files
// listed below.
//
// Any JavaScript/Coffee file within this directory, lib/assets/javascripts, or any plugin's
// vendor/assets/javascripts directory can be referenced here using a relative path.
//
// It's not advisable to add code directly here, but if you do, it'll appear at the bottom of the
// compiled file. JavaScript code in this file should be added after the last require_* statement.
//
// Read Sprockets README (https://github.com/rails/sprockets#sprockets-directives) for details
// about supported directives.
//
//= require_tree .

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

import "controllers"
import * as ActionCable from "@rails/actioncable"
ActionCable.logger.enabled = true
import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false
window.Turbo = Turbo

// Polyfills
// import "core-js/stable";
// import "regenerator-runtime/runtime";
// import 'whatwg-fetch'
// import 'element-closest-polyfill'
// import 'focus-visible/dist/focus-visible.min.js'
// // import "core-js/modules/es7.promise.finally";
// import CustomEvent from 'custom-event-polyfill';

// Object-fit & object-position support for older browsers
// import objectFitImages from 'object-fit-images';
// objectFitImages('img', {watchMQ: true});

// Smooth scroll to anchor links
const SmoothScroll = require('smooth-scroll')
const scroll = new SmoothScroll('a.scrollto', {
  header: '.header',
  easing: 'linear',
  speed: 300,
	speedAsDuration: false,
	durationMax: 800,
	durationMin: 100,
	clip: true
});

// ------------------

// Gather all js files from components folders


  import '/investment-tribe/app/components/cookies_bar/cookies-bar.js';

