import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['required', 'submit']

  connect() {
    this.validate()
  }

  validate() {
    const submittable = this.requiredTargets.every(input => {
      return this.inputIsFilledOut(input)
    })
    this.submitTarget.disabled = !submittable
  }

  inputIsFilledOut(input) {
    return {
      "checkbox": input.checked,
      "file": (input.files && input.files.length),
    }[input.type]
  }
}
