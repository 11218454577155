import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['header']

  connect() {
    const header = this.headerTarget;
    let scrollPos = 0;
    let setPos = false;

    function checkPosition() {
      let windowY = window.scrollY;

      if (windowY < 1) {
        header.classList.remove("is-stuck")
        header.classList.remove("is-off-screen")
      } else {
        header.classList.add("is-stuck")
      }

      if (windowY < scrollPos) {
        // Scrolling UP

        if(!setPos) {
          // apply window.setPosition if not set
          setPos = scrollPos; // windowY;
        }

        if(setPos) {
          // DEBUG
          // console.log('Position set - counting', (setPos - scrollPos));
          // console.log('Y', windowY, 'WP', setPos, 'SP', scrollPos);

          // check if scrolled Xpx since direction change
          if((setPos - scrollPos) > 300) {
            header.classList.remove('is-off-screen');

            // reset setPos
            setPos = false
          }
        }
      } else {
        // Scrolling DOWN

        // reset setPos
        setPos = false

        if (scrollPos > 100) {
          header.classList.add("is-off-screen")
          header.classList.add('is-stuck');
        }
      }

      scrollPos = windowY;
    }

    function debounce(func, wait = 10, immediate = true) {
      let timeout;
      return function() {
        let context = this, args = arguments;
        let later = function() {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    };

    window.addEventListener('scroll', debounce(checkPosition));
  }
}
