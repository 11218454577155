import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    outputSelector: String,
  };

  connect() {
    this.previewFile();
  }

  previewFile() {
    const file = this.element.files[0];
    const outputElement = document.querySelector(this.outputSelectorValue);
    if (file) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.addEventListener('load', function () {
        outputElement.setAttribute('src', this.result);
      });
    }
  }
}
