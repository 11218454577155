import { Controller } from 'stimulus'
import EditorJS from '@editorjs/editorjs'
import Header from '@editorjs/header'
import List from '@editorjs/list'

export default class extends Controller {
  static targets = ['editor', 'input']
  static values = { content: Object }

  connect() {
    this.fetchContents()
    this.initializeEditor()
  }

  initializeEditor() {
    this.editor = new EditorJS({
      holder: this.element,
      minHeight: 100,
      logLevel: 'ERROR',
      tools: {
        header: {
          class: Header,
          inlineToolbar: ['link'],
        },
        list: {
          class: List,
          inlineToolbar: ['link', 'bold'],
        },
      },
      data: this.contentValue,
      onChange: () => {
        this.saveContents()
      },
    });
  }

  saveContents() {
    this.editor
      .save()
      .then((savedData) => {
        this.inputTarget.value = JSON.stringify(savedData)
      })
      .catch((error) => {
        console.log('Saving failed: ', error)
      });
  }

  fetchContents() {
    try {
      this.contentValue = JSON.parse(this.inputTarget.value)
    } catch (e) {
      this.contentValue = {}
    }
  }
}
