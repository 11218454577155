import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.element.querySelector("input[type=submit]").remove();
  }

  submit() {
    this.element.submit();
  }
}
