import { Controller } from "stimulus"

import Plyr from 'plyr'

export default class extends Controller {
  connect() {
    this.enhanceVideoPlayer(this.element)
  }

  enhanceVideoPlayer(container) {
    const options = {
      hideControls: true,
      settings: []
    }

    new Plyr(container, options)
  }
}
