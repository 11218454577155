import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['megamenu', 'dropdown']

  closeDropdowns(e) {
    const escapeChar = 27
    if (e.keyCode == escapeChar) {
      this.collapseAll()
    }
  }

  toggleMegamenu(e) {
    const header = document.querySelector('.header');
    const megamenuArea = document.getElementById('js-megamenu-area');
    const parent = e.target.parentElement;

    const megamenuId = e.target.dataset.megamenuId
    const megamenuTemplate = document.getElementById(megamenuId)
    const clone = megamenuTemplate.content.cloneNode(true);

    if (parent.classList.contains('active')) {
      this.collapseAll()
    } else {
      this.collapseAll()
      header.classList.add('has-active-nav')
      parent.classList.add('active')
      megamenuArea.appendChild(clone)
    }
  }

  toggleDropdown(e) {
    const header = document.querySelector('.header');
    const dropdown = e.target.parentElement;

    if (dropdown.classList.contains('active')) {
      this.collapseAll()
    } else {
      this.collapseAll()
      header.classList.add('has-active-nav')
      dropdown.classList.add('active')
    }
  }

  collapseAll() {
    const header = document.querySelector('.header');
    const megamenuArea = document.getElementById('js-megamenu-area');

    header.classList.remove('has-active-nav')

    this.dropdownTargets.forEach((dropdown) => {
      dropdown.classList.remove('active')
    })

    this.megamenuTargets.forEach((megamenu) => {
      megamenu.classList.remove('active')
      megamenuArea.innerHTML = ''
    })
  }
}
