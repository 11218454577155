import { Controller } from "stimulus"
import Swiper from 'swiper'

export default class extends Controller {
  static targets = ['nav', 'navList','navItem', 'tab']

  connect() {
    this.generateNav()
    this.initSwiper(this.navTarget)
  }

  generateNav() {
    this.tabTargets.forEach((tab, i) => {
      const navItem = document.createElement('li')
      navItem.classList.add('swiper-slide', 'tabs__nav-item')
      navItem.setAttribute('data-tabs-target' ,'navItem')
      navItem.innerHTML = tab.dataset.label

      // activate first tab & navItem
      if (i == 0) {
        this.toggleNav(navItem) 
        this.toggleTab(tab)
      }

      navItem.addEventListener('click', () => {
        this.toggleNav(navItem) 
        this.toggleTab(tab)
      })

      this.navListTarget.appendChild(navItem)
    })
  }

  toggleNav(item) {
    this.navItemTargets.forEach((t) => { t.classList.remove('active') })
    item.classList.add('active')
  }

  toggleTab(tab) {
    this.tabTargets.forEach((t) => { t.classList.remove('active') })
    tab.classList.add('active')
  }

  initSwiper(el) {
    const swiper = new Swiper(el, {
      slidesPerView: "auto",
      freeMode: true,
      freeModeSticky: true,
      freeModeMinimumVelocity: 0.5,
      grabCursor: true,
      spaceBetween: 16,
      enabled: true,
      breakpoints: {
        1140: {
          enabled: false
        }
      },
      on: {
        fromEdge: function(swiper) { 
          swiper.el.classList.remove('reached-beginning', 'reached-end') 
        },
        reachBeginning: function(swiper) { 
          swiper.el.classList.add('reached-beginning') 
        },
        reachEnd: function(swiper) { 
          swiper.el.classList.add('reached-end') 
        }
      }
    });
  }
}
