import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["personalFieldsetLegend", "organisationFieldset"];

  static values = {
    accountType: String,
  };

  accountTypeValueChanged(value) {
    const organisationInputs = document.querySelectorAll(
      '[name^="user[company]"]'
    );

    [...organisationInputs].forEach((input) => {
      input.disabled = value != "organisation";
    });

    this.toggleFieldVisibility(
      this.organisationFieldsetTargets,
      value == "organisation"
    );

    this.togglePersonalFieldsetLegend(
      this.personalFieldsetLegendTarget,
      value == "organisation"
    );
  }

  accountTypeInputChanged(e) {
    this.accountTypeValue = e.currentTarget.value;
  }

  toggleFieldVisibility(nodes, visible) {
    nodes.forEach((node) => (node.style.display = visible ? "block" : "none"));
  }

  togglePersonalFieldsetLegend(node, isOrganisation) {
    node.innerText = isOrganisation ? "Nominated individual" : "Your details";
  }

  registrationIsTradingAddressInputChanged(e) {
    const isSame = e.target.value === "true";

    const addressInputs = document.querySelectorAll(
      '[name^="user[company_attributes][trading_address]"]'
    );

    [...addressInputs].forEach((input) => {
      input.disabled = isSame;
    });

    document
      .getElementById("trading-address-fields-wrapper")
      .classList.toggle("visually-hidden", isSame);
  }

  isShareholderInputChanged(e) {
    const hasShareholding = e.target.value === "true";

    const shareholdingInputs = document.querySelectorAll(
      '[name^="user[company_attributes][shareholdings]"'
    );

    [...shareholdingInputs].forEach((input) => {
      input.disabled = !hasShareholding;
    });

    const shareholdingNotApplicableInput = document.getElementById(
      "user_company_attributes_shareholdings_not_applicable"
    );
    shareholdingNotApplicableInput.disabled = false;
    shareholdingNotApplicableInput.checked = true;
  }

  addTaxResidency(e) {
    const baseInputs = document.getElementById("base-tax-identifications");
    const fieldset = baseInputs.children[0].cloneNode(true);
    document.getElementById("tax-identifications").appendChild(fieldset);
    document
      .querySelectorAll(
        "#tax-identifications input,#tax-identifications select"
      )
      .forEach((input) => {
        if (input.disabled) {
          input.disabled = false;
        }
      });
    this.recountTaxResidencies();
  }

  recountTaxResidencies() {
    const residencyCounters = document.querySelectorAll(
      "span.tax-residency-count"
    );
    residencyCounters.forEach((span, i) => {
      span.innerText = i;
    });
  }

  removeTaxResidency(e) {
    e.target.parentElement.remove();
    this.recountTaxResidencies();
  }
}
