import { Controller } from "stimulus"
import { Chart, DoughnutController, ArcElement, Legend, Tooltip } from 'chart.js'

export default class extends Controller {
  static targets = ['canvas']

  connect() {
    Chart.register(DoughnutController, ArcElement, Legend, Tooltip)

    const chartData = JSON.parse(this.canvasTarget.dataset.chartdata)
    const defaultBackgrounds = [ '#F94144', '#F3722C', '#F68425','#F8961E', '#F9C74F', '#C5C35E', '#90BE6D', '#43AA8B', '#4D908E', '#577590' ].sort(() => Math.random() - 0.5);
    let labels = []
    let data = []
    let dataDetails = []
    let backgrounds = []

    const legendPosition = this.canvasTarget.dataset.legendPosition
    const disableInteractions = this.canvasTarget.dataset.disableInteractions != undefined ? true : false

    for (const [k, v] of Object.entries(chartData)) {
      labels.push(k)
      data.push(v.value)
      dataDetails.push(v.details)
      if (v.color != undefined) {
        backgrounds.push(v.color)
      }
    }

    backgrounds = backgrounds.length != 0 ? backgrounds : defaultBackgrounds

    Chart.defaults.font.family = "'MTT Milano', sans-serif";
    Chart.defaults.font.size = 14;
    const chart = new Chart(this.canvasTarget, {
      type: 'doughnut',
      options: {
        aspectRatio: legendPosition == 'right' || legendPosition == 'left' ? 1.75 : .75,
        responsive: true,
        borderWidth: 1,
        borderColor: "#082c37",
        cutout: '66%',
        interaction: { mode: disableInteractions ? null : 'point' },
        plugins: {
          legend: {
            align: "start",
            position: legendPosition,
            labels:{
              padding: 24,
              boxWidth: 12,
              generateLabels: function(a) {
                return a.data.labels
              },
              filter: function(item, chart) {
                return item.text == null || !item.text.some((el) => el.includes('hide_label'));
              }
            }
          },
          tooltip: {
            enabled: !disableInteractions,
            callbacks: {
              label: function(context) {
                var label = context.label.text || '';
                return label;
              }
            }
          }
        },
      },
      data: {
        labels: labels,
        datasets: [{
          data: data,
          backgroundColor: backgrounds
        }]
      },
    })

    function updateAllLabels() {
      const newLabels = [];

      chart.data.datasets[0]['data'].forEach((data, i) => {
        newLabels.push(generateLabel(data, i));
      });

      chart.data.labels = newLabels;
    }

    function generateLabel(d, i) {
      return {
        text: labels[i].split('\n'),
        fontColor: "#000000",
        fillStyle: backgrounds[i % backgrounds.length],
        lineWidth: 0,
      }
    }

    updateAllLabels();
    chart.update();

  }
}

