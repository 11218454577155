import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['partner', 'toggle']

  connect() {
    if (!this.hasToggleTarget) { return }

    this.initialToggleText = this.toggleTarget.innerText
  }

  toggle(e) {
    e.preventDefault()
    const toggleEl = e.target
    this.toggleTarget.classList.toggle('toggled')
    this.toggleTarget.innerHTML = this.toggleTarget.innerHTML == this.initialToggleText ? 'Show less partners' : this.initialToggleText
    this.partnerTargets.forEach((partner) => {
      partner.classList.toggle('show')
    })
  }
}
