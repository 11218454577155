import { Controller } from "stimulus"
import basictable from 'JS/scripts/basictable.js'

export default class extends Controller {
  static targets = ['cell']

  connect() {
    const hasHeader = this.element.classList.contains('js-table--no-header')

    const table = new basictable('.js-table', {
      header: !hasHeader
    })
  }
}
